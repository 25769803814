<template>
  <section class="bg-yellow-light text-mit-icon py-12">
    <div v-view class="animate in-bottom constrain-4/5 relative">
      <h1 class="h2" v-text="payload.title" />
      <div v-html="payload.text" />
      <div class="icon">
        <img :src="payload.icon.normal" class="w-16 h-16" alt="">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
.icon {
  @apply bg-yellow-light;

  position: absolute;
  top: -5rem;
  right: 0;
  border: 2px solid black;
  border-radius: 100%;
  padding: 1.5rem;
}
</style>
